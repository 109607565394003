import React from "react";
import { Layout } from "./src/components/layout/layout";

export const wrapPageElement = ({ element, props }) => {
  const path = element.props?.path;
  const hasBackground =
    path.includes("/imprint/") || path.includes("/privacy/");

  return (
    <Layout {...props} hasBackground={hasBackground}>
      {element}
    </Layout>
  );
};
