import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../utils/use-location-data";

export const GetFooterSection = () => {
  const data = useStaticQuery(graphql`
    query getFooterSection {
      allStrapiFooter {
        nodes {
          locale
          Title
          Infos
          Address
          Zip
          Email
          SMLink {
            url
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiFooter);
};
