import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { defaultLocale, locales } from "../../../config/i18n";

const { NODE_ENV } = process.env;
const siteMetadata = {
  de: {
    title: "Powerfusion",
    description:
      "Die powerfusion GmbH ist ein produktunabhängiger Systemintegrator " +
      "und Komplettanbieter von nachhaltigen regenerativen Konzepten auf " +
      "Basis von hybriden Stromversorgungslösungen, PV-Kraftwerken und Großbatteriespeichern.",
    author: "https://www.csite.at/",
    siteUrl: "https://www.powerfusion.at/",
    image:
      "https://mlmarketing.fra1.cdn.digitaloceanspaces.com/seo/powerfusion-og.png",
  },
  en: {
    title: "Powerfusion",
    description:
      "Powerfusion Ltd is a product independent system integrator " +
      "and full service provider of sustainable regenerative concepts " +
      "based on hybrid power supply solutions, PV power plants and large battery storage systems.",
    author: "https://www.csite.at/",
    siteUrl: "https://www.powerfusion.at/",
    image:
      "https://mlmarketing.fra1.cdn.digitaloceanspaces.com/seo/powerfusion-og.png",
  },
};

const Seo = ({ description, lang, meta, image: metaImage, title }) => {
  const metadata = siteMetadata[lang];
  const metaDescription = description || metadata.description;
  const location = useLocation();

  if (metaImage) {
    metaImage = {
      src: metaImage.src,
      width: metaImage.width || 753,
      height: metaImage.height || 618,
    };
  } else {
    metaImage = {
      src: metadata.image,
      width: 753,
      height: 618,
    };
  }
  const metaTitle = (title ? `${title} | ` : "") + metadata.title;
  const getLocaleHref = (lang) => {
    let pathname = location.pathname;
    // Remove all locale ptaths
    locales.forEach((l) => (pathname = pathname.replace(`/${l}/`, "/")));
    if (defaultLocale === lang) {
      return `${location.origin}${pathname}`;
    }
    return `${location.origin}/${lang}${pathname}`;
  };
  const alternateLinks = [
    {
      rel: "alternate",
      hrefLang: "x-default",
      href: getLocaleHref(defaultLocale),
    },
    ...locales.map((locale) => ({
      rel: "alternate",
      hrefLang: locale,
      href: getLocaleHref(locale),
    })),
  ];
  const scripts = [];
  if (NODE_ENV === "production") {
    scripts.push({
      type: "text/javascript",
      src: "https://cdn.usefathom.com/script.js",
      defer: true,
      "data-site": "PIXZHGMM",
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={[...alternateLinks]}
      script={scripts}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: metadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.src,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ],
        )
        .concat(meta)}
    />
  );
};
Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
};
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  pathname: PropTypes.string,
};
export default Seo;
