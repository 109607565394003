import { useContext } from "react";
import { defaultLocale } from "../../config/i18n";
import { LocaleContext } from "../components/layout/layout";

export const useLocaleLink = (to) => {
  const { locale } = useContext(LocaleContext);
  if (locale === defaultLocale) {
    return to;
  }
  return `/${locale}${to}`;
};
