import "../../styles/styles.scss";
import React from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import Seo from "../seo/seo";

const LocaleContext = React.createContext();

const Layout = ({ children, hasBackground, pageContext: { locale } }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <Seo lang={locale} />
      <Header hasBackground={hasBackground} />
      <main>{children}</main>
      <Footer />
    </LocaleContext.Provider>
  );
};

export { Layout, LocaleContext };
