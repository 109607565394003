import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  activeLink,
  open,
  smallLinks,
  languages,
  activeLocale,
} from "./navigation.module.scss";
import { locales, defaultLocale } from "../../../config/i18n";
import { useContext } from "react";
import { LocaleContext } from "../layout/layout";
import { useLocaleLink } from "../../utils/useLocaleLink";

const Navigation = ({ isOpen, close }) => {
  const { locale } = useContext(LocaleContext);
  const location = useLocation();
  const [localeButtons, setLocaleButtons] = useState([]);
  const links = [
    { to: useLocaleLink("/"), text: "Home" },
    { to: useLocaleLink("/#what-drives-us/"), text: "What drives us" },
    { to: useLocaleLink("/#who-we-are/"), text: "Who we are" },
    { to: useLocaleLink("/#what-we-do/"), text: "What we do" },
    { to: useLocaleLink("/#our-team/"), text: "Our team" },
    { to: useLocaleLink("/#download/"), text: "Download" },
    { to: useLocaleLink("/#partners/"), text: "Partners" },
    { to: useLocaleLink("/#contact/"), text: "Contact" },
  ];

  const onNavClick = () => {
    close();
  };
  const isActive = (link) => {
    if (location.hash === "") {
      return link === "/";
    }
    return link.includes(location.hash);
  };

  useEffect(() => {
    let pathname = location.pathname;
    // Remove all locale paths from current pathname
    locales.forEach(
      (locale) => (pathname = pathname.replace(`/${locale}/`, "/")),
    );
    setLocaleButtons(
      locales.map((locale) => {
        if (defaultLocale === locale) {
          return { locale, link: `//${location.host}${pathname}` };
        }
        return {
          locale,
          link: `//${location.host}/${locale}${pathname}`,
        };
      }),
    );
  }, [location]);

  return (
    <nav
      className={`${isOpen ? `${open}` : ""}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      {links.map((link) => (
        <Link
          key={link.to}
          to={link.to}
          className={isActive(link.to) ? activeLink : ""}
        >
          {link.text}
        </Link>
      ))}
      <div className={smallLinks}>
        <Link
          to={useLocaleLink("/imprint/")}
          partiallyActive={true}
          activeClassName={activeLink}
        >
          Impressum
        </Link>
        <Link
          to={useLocaleLink("/privacy/")}
          partiallyActive={true}
          activeClassName={activeLink}
        >
          Datenschutz
        </Link>
        <div className={languages}>
          {localeButtons.map((button, index) => (
            <div key={button.locale}>
              <a
                className={button.locale === locale ? activeLocale : ""}
                href={button.link}
                onClick={(event) => event.stopPropagation()}
              >
                {button.locale}
              </a>
              {index < locales.length - 1 ? <span>|</span> : null}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
