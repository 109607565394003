import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../utils/use-location-data";

export const GetHeader = () => {
  const data = useStaticQuery(graphql`
    query getHeader {
      allStrapiHeader {
        nodes {
          locale
          Logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiHeader);
};
