import { Link } from "gatsby";
import React from "react";
import { useContext } from "react";
import Image from "../../components/image/image";
import { useLocaleLink } from "../../utils/useLocaleLink";
import { LocaleContext } from "../layout/layout";
import { GetFooterSection } from "./footer-section-query";
import {
  contact,
  details,
  footer,
  footerContact,
  socialMedia,
  footerNav,
} from "./footer.module.scss";

const Footer = () => {
  const data = GetFooterSection();
  const { locale } = useContext(LocaleContext);
  return (
    <footer id="contact/" className={footer}>
      <Link to="/">
        {/* <img src={logo} className={logoC} alt="Powerfusion Logo" /> */}
      </Link>
      <div className={contact}>
        <div className={footerContact}>
          <div className={details}>
            <h2 className={`section-title`}>{data.Title}</h2>
            <p>{data.Infos}</p>
            <br></br>
            <p>{data.Address}</p>
            <p>{data.Zip}</p>
            <br></br>
            <p>
              <a href={`mailto: ${data.Email}`}>{data.Email}</a>
            </p>
          </div>
        </div>
      </div>
      <div className={socialMedia}>
        {data.SMLink?.map((entry) => (
          <a
            aria-label="Social media link"
            key={entry.url}
            href={entry.url}
            target="_blank"
            rel="noreferrer"
          >
            <Image gatsbyImage={entry.image} alt={entry.url} />
          </a>
        ))}
      </div>
      <div className={footerNav}>
        <Link to={useLocaleLink("/imprint/")}>
          {locale === "de" ? "Impressum" : "Imprint"}
        </Link>
        <Link to={useLocaleLink("/privacy/")}>
          {locale === "de" ? "Datenschutz" : "Privacy"}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
