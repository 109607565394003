// extracted by mini-css-extract-plugin
export var activeLink = "navigation-module--activeLink--85f56";
export var activeLocale = "navigation-module--activeLocale--6c263";
export var dBlock = "navigation-module--d-block--77885";
export var dFlex = "navigation-module--d-flex--45e48";
export var dInline = "navigation-module--d-inline--4e590";
export var dLgBlock = "navigation-module--d-lg-block--74f03";
export var dLgFlex = "navigation-module--d-lg-flex--5bb35";
export var dLgInline = "navigation-module--d-lg-inline--e1528";
export var dLgNone = "navigation-module--d-lg-none--078e0";
export var dMdBlock = "navigation-module--d-md-block--e44a0";
export var dMdFlex = "navigation-module--d-md-flex--397c6";
export var dMdNone = "navigation-module--d-md-none--b8926";
export var dNone = "navigation-module--d-none--d4199";
export var languages = "navigation-module--languages--7c284";
export var open = "navigation-module--open--78888";
export var smallLinks = "navigation-module--smallLinks--af735";