import { useContext } from "react";
import { LocaleContext } from "../components/layout/layout";

const useLocationData = (data) => {
  const { locale } = useContext(LocaleContext);
  if (!data?.nodes?.length) {
    return data;
  }
  const localeData = data.nodes.filter((node) => node.locale === locale);
  // TODO: Add fallback to standard locale
  // Collection
  if (localeData.length > 1) {
    return localeData;
  }
  // Single Type
  return localeData[0];
};

export default useLocationData;
