import { Link } from "gatsby";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocaleLink } from "../../utils/useLocaleLink";
import Image from "../image/image";
import Navigation from "../navigation/navigation";
import { GetHeader } from "./header-query";
import {
  headerlogo,
  menuToggle,
  navOpen,
  background,
} from "./header.module.scss";

const Header = ({ navOpenChange, hasBackground }) => {
  const data = GetHeader();
  const [isOpen, setIsOpen] = useState(false);
  const [bgClass, setBgClass] = useState("");

  const close = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.documentElement.style.scrollBehavior = "";
    setIsOpen(false);
    if (navOpenChange) {
      navOpenChange(false);
    }
  };

  const open = () => {
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    setIsOpen(true);
    if (navOpenChange) {
      navOpenChange(true);
    }
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  useEffect(() => setBgClass(hasBackground ? background : ""), [hasBackground]);

  return (
    <header className={bgClass}>
      <Link onClick={close} to={useLocaleLink("/")} className={headerlogo}>
        <Image
          gatsbyImage={data.Logo}
          className={`${isOpen ? navOpen : ""}`}
          alt="powerfusion Logo"
        />
      </Link>
      <Navigation isOpen={isOpen} close={close} />
      <button
        onClick={() => toggle()}
        className={`${menuToggle} ${isOpen ? navOpen : ""}`}
        aria-label="Menu Button"
      >
        <span></span>
      </button>
    </header>
  );
};

export default Header;
